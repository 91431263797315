const suwalki = {
  lat: 54.10076,
  lng: 22.929743,
};

const olecko = {
  lat: 54.043736,
  lng: 22.489855,
};

const sejny = {
  lat: 54.108988,
  lng: 23.344173,
};

const augustow = {
  lat: 53.844183,
  lng: 22.973767,
};

export default [suwalki, olecko, sejny, augustow];
